
module.exports = function companyAnimation() {
    //получаем все секции 
    var johnDeerePhoto = document.querySelector('.company_photo-JD');
    var johnDeereInfo = document.querySelector('.company_info-JD');
    var caseIhPhoto = document.querySelector('.company_photo-CI');
    var caseIhInfo = document.querySelector('.company_info-CI');
    //получаем все кнопки для подробной информации
    var moreBtnJd = document.querySelector('.moreBtn-JD');
    var moreBtnCi = document.querySelector('.moreBtn-CI');
    //получаем все ссылки на переход в каталог
    var catalogLinkJd = document.querySelector('.catalogLink_JD')
    var catalogLinkCi = document.querySelector('.catalogLink_CI')
    //получаем описание компаний
    var companyDiscriptionJd = document.querySelector('.company_discription-JD');
    var companyDiscriptionCi = document.querySelector('.company_discription-CI');
    //получаем кнопки для скрытия подробного описания 
    var arrowBack = document.querySelector('.arrowBack');
    var closeBtn = document.querySelector('.closeBtn');


    //задаем минимальную высоту контейнеру для показа фотографий
    var companyItems = document.querySelector('.company_items');
    var companyContainer = document.querySelector('.company');
    var companyContainerHeight = companyContainer.offsetHeight;
    var companyItemsHeight = companyContainerHeight - 50;
    companyItems.style.minHeight = companyItemsHeight + 'px';


    //анимация для контента JD
    function showDiscriptionJD() {
        companyDiscriptionJd.style.height = 'unset';
        companyDiscriptionJd.style.opacity = '1';
    };
    function openPhotoJD() {
        caseIhInfo.style.display = 'none'
        johnDeerePhoto.style.position = 'relative';
    };
    function showBtnReadMoreJD() {
        moreBtnJd.style.opacity = '1';
        moreBtnJd.style.pointerEvents = 'unset';
    };

    function hideBtnReadMoreJD() {
        moreBtnJd.style.opacity = '0';
        moreBtnJd.style.pointerEvents = 'none';
    };

    function hideDiscriptionJD() {
        companyDiscriptionJd.style.opacity = '0';
        setTimeout(a, 500);
        function a() {
            companyDiscriptionJd.style.height = '0';
        };
    };
    function closePhotoJD() {
        caseIhInfo.style.display = 'flex'
        johnDeerePhoto.style.position = 'absolute';
    };
    function shiftPhotoJD() {
        johnDeerePhoto.style.right = '-100%';
        setTimeout(a, 300)
        function a() {
            caseIhInfo.style.opacity = '1';
        };
    };


    //анимация для контента CI 
    function showDiscriptionCI() {
        companyDiscriptionCi.style.height = 'unset';
        companyDiscriptionCi.style.opacity = '1';
    };
    function openPhotoCI() {
        johnDeereInfo.style.display = 'none'
        caseIhPhoto.style.position = 'relative';
    };
    function showBtnReadMoreCI() {
        moreBtnCi.style.opacity = '1';
        moreBtnCi.style.pointerEvents = 'unset';
    };

    function hideBtnReadMoreCI() {
        moreBtnCi.style.opacity = '0';
        moreBtnCi.style.pointerEvents = 'none';
    };
    function hideDiscriptionCI() {
        companyDiscriptionCi.style.opacity = '0';
        setTimeout(a, 500);
        function a() {
            companyDiscriptionCi.style.height = '0';
        };
    };
    function closePhotoCI() {
        johnDeereInfo.style.display = 'flex'
        caseIhPhoto.style.position = 'absolute';
    };
    function shiftPhotoCI() {
        caseIhPhoto.style.left = '-100%';
        setTimeout(a, 300)
        function a() {
            johnDeereInfo.style.opacity = '1';
        };
    };


    //показ закрывающих кнопок
    function showCloseBtns() {
        arrowBack.style.opacity = '1';
        arrowBack.style.pointerEvents = 'unset';
        closeBtn.style.opacity = '1';
        closeBtn.style.pointerEvents = 'unset';
    };
    //скрытие закрывающих кнопок
    function hideCloseBtns() {
        arrowBack.style.opacity = '0';
        arrowBack.style.pointerEvents = 'none';
        closeBtn.style.opacity = '0';
        closeBtn.style.pointerEvents = 'none';
    };





    //анимация для страницы о компаниях при ширине экрана больше 768px 
    if (window.innerWidth > 768) {

        //анимация закрытия подробной информации 
        function hideFullInformation() {
            hideCloseBtns();
            if (companyItems.classList.contains('activeCompanyJD')) {
                companyItems.classList.remove('activeCompanyJD');
                closePhotoJD();
                hideDiscriptionJD();
                showBtnReadMoreJD();
                setTimeout(shiftPhotoJD, 500);
            } else if (companyItems.classList.contains('activeCompanyCI')) {
                companyItems.classList.remove('activeCompanyCI');
                closePhotoCI();
                hideDiscriptionCI();
                showBtnReadMoreCI();
                setTimeout(shiftPhotoCI, 500);
            }
        };


        //показ подробного описания 
        moreBtnJd.addEventListener('click', () => {
            companyItems.classList.add('activeCompanyJD');
            caseIhInfo.style.opacity = '0';
            johnDeerePhoto.style.right = '0';
            hideBtnReadMoreJD();
            setTimeout(showDiscriptionJD, 1000);
            setTimeout(openPhotoJD, 1000);
            setTimeout(showCloseBtns, 1500);
        });

        moreBtnCi.addEventListener('click', () => {
            companyItems.classList.add('activeCompanyCI');
            johnDeereInfo.style.opacity = '0';
            caseIhPhoto.style.left = '0';
            hideBtnReadMoreCI();
            setTimeout(showDiscriptionCI, 1000);
            setTimeout(openPhotoCI, 1000);
            setTimeout(showCloseBtns, 1500);
        });

        //закрытие подробного описания 
        closeBtn.addEventListener('click', () => {
            hideFullInformation();
        });
        arrowBack.addEventListener('click', () => {
            hideFullInformation();
        });
    }

    //анимация для страницы о компаниях при ширине экрана меньше 768px 
    if (window.innerWidth < 769) {
        johnDeereInfo.insertBefore(johnDeerePhoto, catalogLinkJd);
        caseIhInfo.insertBefore(caseIhPhoto, catalogLinkCi);

        function hideFullInformation() {
            if (companyItems.classList.contains('activeCompanyJD')) {
                hideCloseBtns();
                companyItems.classList.remove('activeCompanyJD');
                hideDiscriptionJD();
                showBtnReadMoreJD();
                johnDeerePhoto.style.opacity = '0';
                johnDeerePhoto.style.position = 'absolute';
                catalogLinkJd.style.marginTop = '0';
                catalogLinkJd.style.marginBottom = '0';
            } else if (companyItems.classList.contains('activeCompanyCI')) {
                hideCloseBtns();
                companyItems.classList.remove('activeCompanyCI');
                hideDiscriptionCI();
                showBtnReadMoreCI();
                caseIhPhoto.style.opacity = '0';
                caseIhPhoto.style.position = 'absolute';
                catalogLinkCi.style.marginTop = '0';
                catalogLinkCi.style.marginBottom = '0';
            }
        }


        //показ подробной информации
        moreBtnJd.addEventListener('click', () => {
            hideFullInformation();
            companyItems.classList.add('activeCompanyJD');
            johnDeerePhoto.style.position = 'relative';
            johnDeerePhoto.style.opacity = '1';
            catalogLinkJd.style.marginTop = '30px';
            catalogLinkJd.style.marginBottom = '30px';
            hideBtnReadMoreJD();
            showDiscriptionJD();
            johnDeereInfo.appendChild(closeBtn);
            johnDeereInfo.appendChild(arrowBack);
            showCloseBtns();
        });
        moreBtnCi.addEventListener('click', () => {
            hideFullInformation();
            companyItems.classList.add('activeCompanyCI');
            caseIhPhoto.style.position = 'relative';
            caseIhPhoto.style.opacity = '1';
            catalogLinkCi.style.marginTop = '30px';
            catalogLinkCi.style.marginBottom = '30px';
            hideBtnReadMoreCI();
            showDiscriptionCI();
            caseIhInfo.appendChild(closeBtn);
            caseIhInfo.appendChild(arrowBack);
            showCloseBtns();
        });

        //скрытие подробной информации 
        closeBtn.addEventListener('click', () => {
            hideFullInformation();
        });
        arrowBack.addEventListener('click', () => {
            hideFullInformation();
        });
    }
}







































