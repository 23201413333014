module.exports = function stock() {
    //получаем все карточки товаров
    const items = document.querySelectorAll('.stock_item');
    //скрываем все товары кроме 5 первых
    items.forEach((stock_item, i) => {
        if (i > 4) {
            stock_item.classList.add('hide')
        }
    });

    //получаем кнопку "показать ещё"
    const showmore = document.querySelector('.showMore');
    //функция добавления товаров при клике на кнопку
    function reveal5(e) {
        if (e) {
            e.preventDefault();
        }
        //получаем массив скрытых карточек товаров
        const arrayOfHidden = Array.from(document.querySelectorAll('.stock_item.hide'));


        //добавляем по 5 карточек к видимым
        for (let i = 0; i < 5; i++) {
            try {
                arrayOfHidden[i].classList.remove('hide')
            } catch (error) {
                break;
            }
        }

        //скрываем кнопку "показать ещё" если все товары показаны
        if (arrayOfHidden.length < 6) {
            // showmore.removeEventListener('click', reveal5);
            showmore.remove();
        }
    }

    //получаем все скрытые товары
    if (!document.querySelectorAll('.stock_item.hide').length) {
        showmore.remove();
    } else {
        showmore.addEventListener('click', reveal5);
    }

};